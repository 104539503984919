import classes from './MainSwitch.module.css';

const MainSwitch = (props) => {
    return (
        <div className={classes.MainSwitchWrapper}>
            <input
                className={props.checked ? [classes.MainSwitch, classes.Checked].join(' ') : classes.MainSwitch}
                type='checkbox'
                role='switch'
                color={props.color}
                name={props.name}
                checked={props.checked}
                onChange={props.onChange}
            />
            <label>{props.label}</label>
        </div>
    );
};

export default MainSwitch;
