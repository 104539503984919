import classes from './Widget.module.css';

const Widget = (props) => {
    let elClasses = [classes.Widget];
    if (props.noPadding) elClasses.push(classes.NoPadding);

    return (
        <div className={elClasses.join(' ')}>
            <div className={props.showCurtain ? [classes.Curtain, classes.Show].join(' ') : classes.Curtain}>
                <div className={classes.AlertMessage}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='8' x2='12' y2='12'></line>
                        <line x1='12' y1='16' x2='12' y2='16'></line>
                    </svg>
                    <span>
                        <strong>Warning!</strong>
                        {props.curtainMessage}
                    </span>
                </div>
            </div>
            {props.children}
        </div>
    );
};

export default Widget;
